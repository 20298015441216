<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="用户信息">
              <a-input
                placeholder="请输入姓名/身份证号/手机号"
                v-model="search.name"
              />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="状态">
              <a-select v-model="search.status" allowClear placeholder="请选择">
                <a-select-option :value="0">禁用</a-select-option>
                <a-select-option :value="1">启用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="3" :style="{ textAlign: 'left' }">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <pxkeji-btn
          name="添加"
          class="pxkeji_basic_btn"
          @click="showInfo('0')"
          type="primary"
          :roles="['学员-学员添加']"
        />
        <pxkeji-btn
          name="下载模版"
          class="pxkeji_basic_btn"
          @click="downloadTemplate"
          type="primary"
        />
        <pxkeji-btn
          name="导出用户"
          class="pxkeji_basic_btn"
          @click="exportData"
        />
        <!--        <pxkeji-btn-->
        <!--          name="批量启用"-->
        <!--          v-if="selectRows.length > 0"-->
        <!--          class="pxkeji_basic_btn"-->
        <!--          @click="batchEnable"-->
        <!--        />-->
        <!--        <pxkeji-btn-->
        <!--          name="批量禁用"-->
        <!--          v-if="selectRows.length > 0"-->
        <!--          class="pxkeji_basic_btn"-->
        <!--          @click="batchDisEnable"-->
        <!--        />-->
        <a-upload
          name="file"
          :action="$api.student.importData"
          accept=".xls, .xlsx"
          @change="handleChange"
          :showUploadList="false"
        >
          <a-button> <a-icon type="upload" />导入用户 </a-button>
        </a-upload>
        <span style="margin-left: 8px">
          <template v-if="selectRows.length > 0">
            {{ `已选择 ${selectRows.length} 项` }}
            <a-button type="link" @click="clearRows"> 取消选择 </a-button>
          </template>
        </span>
      </div>
    </div>

    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :rowSelection="{
          type: 'checkbox',
          onChange: onRowChange,
          columnWidth: 40,
          selectedRowKeys: selectRows,
        }"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <template slot="sex" slot-scope="sex">
          {{ sex == 1 ? "男" : sex == 2 ? "女" : "" }}
        </template>
        <template slot="sourceType" slot-scope="sourceType">
          {{
            sourceType == 0
              ? "个人注册"
              : sourceType == 1
              ? "批量导入"
              : "后台创建"
          }}
        </template>
        <template slot="status" slot-scope="status">
          <a-tag v-if="status == 0" color="red">禁用</a-tag>
          <a-tag v-else color="green">启用</a-tag>
        </template>
        <span slot="action" slot-scope="action">
          <pxkeji-btn
            name="编辑"
            :roles="['学员-学员修改']"
            @click="showInfo(action.id)"
            size="small"
            type="primary"
          />
          <a-popconfirm
            title="是否确认禁用此学员"
            @confirm="updateStatus(action.id, 0)"
            v-if="action.status == 1"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="禁用"
              size="small"
              type="danger"
              :roles="['学员-学员启用禁用']"
            />
          </a-popconfirm>
          <a-popconfirm
            title="是否确认启用此学员"
            @confirm="updateStatus(action.id, 1)"
            v-else
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="启用"
              size="small"
              :roles="['学员-学员启用禁用']"
            />
          </a-popconfirm>
          <!--<pxkeji-btn-->
          <!--name="积分明细"-->
          <!--:roles="[]"-->
          <!--@click="integralInfo(action)"-->
          <!--size="small"-->
          <!--type="dashed"-->
          <!--/>-->
        </span>
      </a-table>
    </div>

    <a-modal
      :title="addedit.title"
      v-model="addedit.visible"
      width="900px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <StudentInfo @callbackMethod="save" :id="addedit.id" />
    </a-modal>

    <a-modal
      title="积分明细"
      v-model="integral.visible"
      width="1000px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <integralRecord
        @callbackMethod="save"
        :id="integral.id"
        :infos="integral.info"
      />
    </a-modal>
  </div>
</template>
<script>
import StudentInfo from "./StudentInfo.vue"; //新增编辑
import integralRecord from "./integralRecord.vue"; //积分明细
const columns = [
  {
    dataIndex: "fullName",
    align: "left",
    title: "姓名",
  },
  {
    dataIndex: "sex",
    align: "center",
    title: "性别",
    width: 100,
    scopedSlots: { customRender: "sex" },
  },
  {
    dataIndex: "telephone",
    align: "center",
    title: "手机号",
  },
  // {
  //   dataIndex: "idType",
  //   align: "center",
  //   title: "证件类型",
  // },
  {
    dataIndex: "idCard",
    align: "center",
    title: "证件号",
  },
  {
    dataIndex: "sourceType",
    key: "sourceType",
    align: "center",
    title: "注册方式",
    width: 140,
    scopedSlots: { customRender: "sourceType" },
  },
  {
    dataIndex: "status",
    key: "status",
    align: "center",
    title: "状态",
    width: 120,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "action" },
  },
];
let excelLoading = "";
let _this;
export default {
  name: "StudentList",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 260,
      },
      addedit: {
        title: "",
        id: 0,
        visible: false,
      },
      //积分明细
      integral: {
        info: {},
        id: 0,
        visible: false,
      },
      search: {
        //搜索条件
        name: "",
      },
      selectRows: [], //选中项编号
      isloading: false,
    };
  },
  components: {
    StudentInfo,
    integralRecord,
  },
  mounted() {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 292;
      _this.innerWidth = window.innerWidth;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.GetList();
  },
  methods: {
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.clearRows();
      _this.$api.student
        .getPagedList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.search
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    onRowChange(selectedRowKeys) {
      //table 多选
      _this.selectRows = selectedRowKeys;
    },
    clearRows() {
      //清空选中项
      _this.selectRows = [];
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    showInfo(id) {
      //打开弹出框
      _this.addedit.title = id == "0" ? "添加" : "编辑";
      _this.addedit.id = id;
      _this.addedit.visible = true;
    },
    /*禁用启用 */
    updateStatus(ids, status) {
      _this.isloading = true;
      _this.$api.user
        .prohibit(ids, status)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    save() {
      _this.addedit.visible = false;
      _this.integral.visible = false;
      _this.GetList();
    },
    downloadTemplate() {
      window.location.href = _this.$api.student.downloadTemplate();
    },
    exportData() {
      let data = {
        name: _this.search.name,
        studentIds: _this.selectRows.join(),
      };
      window.location.href = _this.$api.student.exportData(data);
    },
    handleChange(info) {
      if (info.file.status == "uploading") {
        if (excelLoading == "")
          excelLoading = this.$message.loading("上传中", 0);
      } else if (info.file.status == "done") {
        excelLoading();
        excelLoading = "";
        if (info.file.response.errorCode == this.$msg.responseCode.success) {
          this.$message.success(info.file.response.errorMsg);
          this.GetList();
        } else {
          this.$message.error(info.file.response.errorMsg);
        }
      }
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>

