<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="studentForm"
        :rules="rules"
        :model="info"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="姓名" prop="fullName">
          <a-input
            placeholder="请输入"
            v-model="info.fullName"
            :maxLength="25"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="性别" prop="sex">
          <a-radio-group v-model="info.sex">
            <a-radio value="1">男</a-radio>
            <a-radio value="2">女</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="telephone">
          <a-input
            placeholder="请输入"
            v-model="info.telephone"
            :maxLength="11"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="身份证号" prop="idCard">
          <a-input
            placeholder="请输入"
            v-model="info.idCard"
            :maxLength="18"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
let _this;
export default {
  props: {
    id: String,
  },
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
      info: {
        idType: "居民身份证",
      },
      rules: {
        fullName: [
          {
            required: true,
            message: "请输入真实姓名",
            trigger: "blur",
          },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        telephone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        idCard: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
        ],
      },
    };
  },
  components: {},
  mounted() {
    _this = this;
    if (_this.id != "0") {
      //编辑
      _this.getInfo();
    }
  },
  methods: {
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    /**数据回显 */
    getInfo() {
      _this.isloading = true;
      _this.$api.student
        .getById(_this.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },

    handleSubmit() {
      _this.$refs.studentForm.validate((vlaid) => {
        if (vlaid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          let formData = { ..._this.info };
          if (_this.id != "0") {
            formData.id = _this.id;
          }
          //新增
          _this.$api.student
            .saveOrUpdateStudent(formData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
</style>