<template>
  <div>
    <div class="pxkeji-right-box">
      <!-- <pxkeji-btn
        name="添加"
        class="pxkeji_basic_btn"
        @click="showInfo(0)"
        type="primary"
      />-->
      <!-- <pxkeji-btn name="批量导出" v-if="selectRows.length>0" class="pxkeji_basic_btn" />
      <pxkeji-btn
        name="批量启用"
        :roles="['优惠券-启用禁用']"
        v-if="selectRows.length>0"
        class="pxkeji_basic_btn"
        @click="batchEnable"
      />
      <pxkeji-btn
        name="批量禁用"
        :roles="['优惠券-启用禁用']"
        v-if="selectRows.length>0"
        class="pxkeji_basic_btn"
        @click="batchDisEnable"
      />

      <a-alert type="info" showIcon style="margin-bottom:20px">
        <span slot="message">
          已选择 {{selectRows.length}} 项
          <a
            @click="clearRows"
            style="color: #1890ff;margin-left:10px"
          >清空</a>
        </span>
      </a-alert>-->

      <a-table
        :columns="list.columns"
        rowKey="id"
        :pagination="list.pagination"
        @change="onChange"
        :dataSource="list.datas"
        :loading="isloading"
        :locale="{ emptyText: '暂无数据' }"
      >
        <span slot="count" slot-scope="count">
          <span v-if="count == -1">不限</span>
          <span v-else>{{ count }}</span>
        </span>
        <span slot="status" slot-scope="status">
          <a-tag v-if="status == 1" color="green">增加</a-tag>
          <a-tag v-else color="red">减少</a-tag>
        </span>
        <span slot="action" slot-scope="action">
          <pxkeji-btn
            name="编辑"
            :roles="['专家-编辑']"
            @click="showInfo(action.id, action)"
            size="small"
            type="primary"
          />
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
var that;
const columns = [
  {
    dataIndex: "id",
    key: "id",
    align: "center",
    title: "记录编号",
  },
  {
    dataIndex: "body",
    key: "body",
    align: "center",
    title: "规则",
  },
  {
    dataIndex: "count",
    key: "count",
    align: "center",
    title: "积分",
  },
  {
    dataIndex: "addTime",
    key: "addTime",
    align: "center",
    title: "日期",
  },
  {
    dataIndex: "countType",
    key: "countType",
    align: "center",
    title: "状态",
    scopedSlots: { customRender: "status" },
  },
];

export default {
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
    id: 0,
  },
  data() {
    return {
      expand: false, //高级搜索
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          //分页器
          showQuickJumper: true,
          hideOnSinglePage: true,
          total: 0,
          defaultCurrent: 1, //页码
          pageSize: 10, //页容
        },
      },
      addedit: {
        title: "",
        info: {},
        id: 0,
        visible: false,
      },
      where: {
        //查询条件
        keyword: {
          //搜索条件
          number: "",
          name: "",
          status: "",
        },
      },
      selectRows: [], //选中项编号
      isloading: false,
      visible: false, //弹框
    };
  },
  components: {},
  mounted: function () {
    this.$nextTick(function () {
      that = this;
      this.GetList();
    });
  },
  watch: {
    selectRows: function () {
      //console.log("watch", val);
    },
  },
  methods: {
    toggle() {
      this.expand = !this.expand;
    },
    GetList() {
      //console.log("userId",this.id);
      //获取列表
      this.isloading = true;
      this.clearRows();
      this.$api.student
        .getMemberLogList(
          this.list.pagination.defaultCurrent,
          this.list.pagination.pageSize,
          this.id,
          1
        )
        .then((res) => {
          this.isloading = false;
          if (res.errorCode == this.$msg.responseCode.success) {
            this.list.datas = res.data.records;
            this.list.pagination.total = Number(res.data.total);
          } else {
            that.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    onTimeChange(date, dateString) {
      this.where.keyword.startTime = dateString[0];
      this.where.keyword.endTime = dateString[1];
    },
    onChange(page) {
      //页面切换回调
      this.list.pagination.defaultCurrent = page.current;
      this.GetList();
    },
    onRowChange(selectedRowKeys) {
      //table 多选
      this.selectRows = selectedRowKeys;
    },
    clearRows() {
      //清空选中项
      this.selectRows = [];
    },
    searchList() {
      this.list.pagination.defaultCurrent = 1;
      this.GetList();
    },
    clear() {
      //清空搜索条件
      for (var info in this.where.keyword) {
        this.where.keyword[info] = "";
      }
    },
    showInfo(id, info) {
      //打开弹出框
      this.addedit.visible = true;
      this.addedit.title = id ? "编辑" : "新增";
      this.addedit.id = id;
      this.addedit.info = info;
    },
    /*批量启用 */
    batchEnable() {
      this.handleProhibit(this.selectRows.join(","), 1);
    },
    /*批量禁用 */
    batchDisEnable() {
      this.$confirm({
        title: "您确定要执行批量禁用的操作吗?",
        okText: "确定",
        okType: "danger",
        cancelText: "我再想想",
        onOk() {
          that.handleProhibit(that.selectRows.join(","), 0);
        },
        onCancel() {},
      });
    },
    /*禁用启用 */
    handleProhibit(ids, status) {
      that.isloading = true;
      that.$api.operation.coupon
        .updateBatchById(ids, status)
        .then((res) => {
          that.isloading = false;
          if (res.errorCode == that.$msg.responseCode.success) {
            that.$message.success(res.errorMsg);
            that.GetList();
          } else {
            that.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          that.isloading = false;
        });
    },
    /**删除 */
    delInfo(id) {
      this.$confirm({
        title: "您确定要删除此项吗?",
        okText: "确定",
        okType: "danger",
        cancelText: "我再想想",
        onOk() {
          that.isloading = true;
          that.$api.operation.coupon
            .removeById(id)
            .then((res) => {
              that.isloading = false;
              if (res.errorCode == that.$msg.responseCode.success) {
                that.$message.success(res.errorMsg);
                that.GetList();
              } else {
                that.$message.error(res.errorMsg);
              }
            })
            .catch(() => {
              that.isloading = false;
            });
        },
        onCancel() {},
      });
    },
    /**发券 */
    giveCoupon(id) {
      this.issuing.visible = true;
      this.issuing.id = id;
    },
    /**明细 */
    voidCoupon(id) {
      this.voids.visible = true;
      this.voids.id = id;
    },
    save() {
      this.addedit.visible = false;
      this.GetList();
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>

